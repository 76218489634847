import React from "react";
import "./about.scss";
import logo from "../../Media/sb-logo.png";

const aboutparkspring = () => {
  return (
    <div id="about" className="container-aboutparkspring">
      <div className="logo-aboutparkspring">
        <img
          className="gambar-logoparkspring"
          src={logo}
          alt="logo-parkspring"
        />
      </div>
      <div className="container-deskripsiparkspring">
        <h1>Enhanced Your Living</h1>
        <div className="deskripsi-aboutparkspring">
          Summarecon Bekasi adalah sebuah kawasan perumahan dan komersial yang
          dikembangkan oleh PT Summarecon Agung Tbk di Bekasi Utara, Kota
          Bekasi, Jawa Barat, Indonesia. Summarecon Bekasi menyediakan beberapa
          pilihan cluster rumah seperti Magenta, Olive, Burgundy. Mulberry, dan
          Morizen. Dalam opsi lain Summarecon Bekasi juga menawarkan apartement
          seperti Spring Lake View dan The Primrose Condovilla. Untuk pusat
          bisnis di Summarecon Bekasi menyediakan Ruko Crystal Boulevard. Dengan
          Banyaknya pilihan yang ditawarkan Summarecon Bekasi sendiri memiliki
          luas kawasan sekitar 270 Hektar.
        </div>
      </div>
    </div>
  );
};

export default aboutparkspring;
