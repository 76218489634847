import Mulberry1 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (1).webp'
import Mulberry2 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (2).webp'
import Mulberry3 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (3).webp'
import Mulberry4 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (4).webp'
import Mulberry5 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (5).webp'
import Mulberry6 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (6).webp'
import Mulberry7 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (7).webp'
import Mulberry8 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (8).webp'
import Mulberry9 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (9).webp'
import Mulberry10 from '../../../Media/Summarecon Bekasi/Mulberry/Mulberry (10).webp'

const Mulberryimages =[
Mulberry1,Mulberry2,Mulberry3,Mulberry4,Mulberry5,Mulberry6,Mulberry7,Mulberry8,Mulberry9,Mulberry10
]

export default Mulberryimages;