import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Burgundyimages from "../Produk1/Burgundy";
import Morizenimages from "./Morizen";
import Mulberryimages from "./Mulberry";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Lemonadewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628979166916&text=Halo+Reza%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Maison)%20https://www.jgc-jakartagardencity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Gardenwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282123360955&text=Halo+Reza%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Gran%20Jura)%20https://www.jgc-jakartagardencity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo+Lena%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Summarecon%20Bekasi%20(Mulberry)%20https://www.marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const WTwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo+Lena%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Summarecon%20Bekasi%20(Morizen)%20https://www.marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="container-card3">
        <div className="card">
          <Slider {...settings}>
            {Mulberryimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Mulberry</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">10</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 117m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 133m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cherrywa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Morizenimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Morizen</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">16</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 216m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 362m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 4+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={WTwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
