import React from "react";
import "./apartemen1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Springlakemages from "./Springlake";
import Primroseimages from "./Primrose";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLayerGroup,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const ruko1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const cleonwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo%20Lena,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bekasi%20(The%20Springlake%20View)%20https://marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  const neweastwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo%20Lena,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Summarecon%20Bekasi%20(The%20Primrose%20Condovilla)%20https://marketingsummabekasi.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container-apartemen">
      <div className="container-apartemen">
        <div className="card">
          <Slider {...settings}>
            {Springlakemages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">The Springlake View</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#FBBF68"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni, Full Furnished</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">2</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 49m²</span>
            <FontAwesomeIcon color="white" icon={faLayerGroup} />
            <span> 3</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={cleonwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Primroseimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">The Primrose Condovilla</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#FBBF68"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni, Free DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">7</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 99m²</span>
            <FontAwesomeIcon color="white" icon={faLayerGroup} />
            <span> 3+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={neweastwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ruko1;
