import Surrounding1 from '../../../src/Media/Kawasan Sumbek/Kawasan (1).webp'
import Surrounding2 from '../../../src/Media/Kawasan Sumbek/Kawasan (2).webp'
import Surrounding3 from '../../../src/Media/Kawasan Sumbek/Kawasan (3).webp'
import Surrounding4 from '../../../src/Media/Kawasan Sumbek/Kawasan (4).webp'
import Surrounding5 from '../../../src/Media/Kawasan Sumbek/Kawasan (5).webp'
import Surrounding6 from '../../../src/Media/Kawasan Sumbek/Kawasan (6).webp'
import Surrounding7 from '../../../src/Media/Kawasan Sumbek/Kawasan (7).webp'
import Surrounding8 from '../../../src/Media/Kawasan Sumbek/Kawasan (8).webp'
import Surrounding9 from '../../../src/Media/Kawasan Sumbek/Kawasan (9).webp'
import Surrounding10 from '../../../src/Media/Kawasan Sumbek/Kawasan (10).webp'
import Surrounding11 from '../../../src/Media/Kawasan Sumbek/Kawasan (11).webp'





const Surroundingimages =[
    Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6,Surrounding7,Surrounding8,Surrounding9,Surrounding10,Surrounding11
]

export default Surroundingimages;