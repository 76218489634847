import logorumah1 from '../../Media/Logo_Sumbek (1)/logo (1).png'
import logorumah2 from '../../Media/Logo_Sumbek (1)/logo (2).png'
import logorumah3 from '../../Media/Logo_Sumbek (1)/logo (3).png'
import logorumah4 from '../../Media/Logo_Sumbek (1)/logo (4).png'
import logorumah5 from '../../Media/Logo_Sumbek (1)/logo (5).png'
import logorumah6 from '../../Media/Logo_Sumbek (1)/logo (6).png'
import logorumah7 from '../../Media/Logo_Sumbek (1)/logo (7).png'

const logorumahimages =[
logorumah1, logorumah2, logorumah3, logorumah4, logorumah5, logorumah6, logorumah7
]

export default logorumahimages;