import React from "react";
import "./promo.scss";
import penawaran from "../../Media/hardsell_sumbek.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faMedal} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179856898&text=Halo+Lena,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20rumah%20Summarecon%20Bekasi%20(Promo)+https://marketingsummabekasi.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  return (
    <div className="judulpromo">
      <div id="promo" className=" containerPenawaran">
        <div className="containercontent">
          <div className="judul">
            <h1>PROMO SUMMARECON BEKASI</h1>
          </div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Discount up to 10%
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free PPN</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Biaya BPHTB</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free KPA</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free AC</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Full Furnished</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free IPL</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Full Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smartdoor Lock
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} />
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
