import React from "react";
import "./footer.scss";
import logo from "../../Media/sb-logo.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Summarecon Bekasi</h2>
            <h3>
              Jl. Bulevar Ahmad Yani, Marga Mulya, Kec. Bekasi Utara, Kota Bks,
              Jawa Barat 17142
            </h3>
            <h4>Contact us : +6285179856898</h4>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">© Summarecon Bekasi</div>
    </div>
  );
};

export default footer;
