import React from "react";
import "./apartemen.scss";
import Apartemen1 from "./Produk1/apartemen1";
import springlakelogo from "../../Media/Logo_Sumbek (1)/springlake.png";
import primroselogo from "../../Media/Logo_Sumbek (1)/primrose.png";

const settings1 = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ruko = () => {
  return (
    <div id="apartemen" className="apartemen">
      <div className="Judul"> APARTEMENT IN SUMMARECON BEKASI</div>
      <div className="apartemen-image">
        <div className="springlakelogo">
          <img src={springlakelogo} alt="springlakelogo" />
        </div>
        <div className="primroselogo">
          <img src={primroselogo} alt="primroselogo" />
        </div>
      </div>
      <div className="ruko-card">
        <Apartemen1 />
      </div>
    </div>
  );
};

export default ruko;
